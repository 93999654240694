import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { isAddress } from '../../utils/index.js'
import EthereumLogo from '../../assets/eth.png'
import MaticLogo from '../../assets/matic.png'
import FliqLogo from '../../assets/fliq.png'
import ManaLogo from '../../assets/mana.png'
import SandLogo from '../../assets/sand.png'
import { useActiveNetwork } from '../../contexts/LocalStorage.js'
import { NETWORKS } from '../../apollo/client.js'

const BAD_IMAGES = {}

const Inline = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
`

const Image = styled.img`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
`

const StyledEthereumLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: ${({ size }) => size};
    height: ${({ size }) => size};
  }
`

export default function TokenLogo({ address, header = false, size = '24px', ...rest }) {
  const [error, setError] = useState(false)
  const [chainId] = useActiveNetwork()
  const networkActive = NETWORKS.find((net) => net.chainId === chainId).label2
  useEffect(() => {
    setError(false)
  }, [address])

  if (error || BAD_IMAGES[address]) {
    return (
      <Inline>
        <span {...rest} alt={''} style={{ fontSize: size }} role="img" aria-label="face">
          ❓
        </span>
      </Inline>
    )
  }

  // hard coded fixes for trust wallet api issues
  if (address?.toLowerCase() === '0x5e74c9036fb86bd7ecdcb084a0673efc32ea31cb') {
    address = '0x42456d7084eacf4083f1140d3229471bba2949a8'
  }

  if (address?.toLowerCase() === '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f') {
    address = '0xc011a72400e58ecd99ee497cf89e3775d4bd732f'
  }

  if (address?.toLowerCase() === '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619') {
    return (
      <StyledEthereumLogo size={size} {...rest}>
        <img
          src={EthereumLogo}
          style={{
            boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.075)',
            borderRadius: '24px',
          }}
          alt=""
        />
      </StyledEthereumLogo>
    )
  }

  else if (address?.toLowerCase() === '0x4200000000000000000000000000000000000006') {
    return (
      <StyledEthereumLogo size={size} {...rest}>
        <img
          src={EthereumLogo}
          style={{
            boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.075)',
            borderRadius: '24px',
          }}
          alt=""
        />
      </StyledEthereumLogo>
    )
  }

  else if (address?.toLowerCase() === '0x82af49447d8a07e3bd95bd0d56f35241523fbab1') {
    return (
      <StyledEthereumLogo size={size} {...rest}>
        <img
          src={EthereumLogo}
          style={{
            boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.075)',
            borderRadius: '24px',
          }}
          alt=""
        />
      </StyledEthereumLogo>
    )
  }

  else if (address?.toLowerCase() === '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270') {
    return (
      <StyledEthereumLogo size={size} {...rest}>
        <img
          src={MaticLogo}
          style={{
            boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.075)',
            borderRadius: '24px',
          }}
          alt=""
        />
      </StyledEthereumLogo>
    )
  }

  else if (address?.toLowerCase() === '0xa1c57f48f0deb89f569dfbe6e2b7f46d33606fd4') {
    return (
      <StyledEthereumLogo size={size} {...rest}>
        <img
          src={ManaLogo}
          style={{
            boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.075)',
            borderRadius: '24px',
          }}
          alt=""
        />
      </StyledEthereumLogo>
    )
  }

  else if (address?.toLowerCase() === '0xbbba073c31bf03b8acf7c28ef0738decf3695683') {
    return (
      <StyledEthereumLogo size={size} {...rest}>
        <img
          src={SandLogo}
          style={{
            boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.075)',
            borderRadius: '24px',
          }}
          alt=""
        />
      </StyledEthereumLogo>
    )
  }

  else if (address?.toLowerCase() === '0x03079F967A37cCAc6eb01d5dcC98FC45E6b57517') {
    return (
      <StyledEthereumLogo size={size} {...rest}>
        <img
          src={FliqLogo}
          style={{
            boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.075)',
            borderRadius: '24px',
          }}
          alt=""
        />
      </StyledEthereumLogo>
    )
  }

  else if (address?.toLowerCase() === '0x5fe2b58c013d7601147dcdd68c143a77499f5531') {
    return (
      <Inline>
        <Image
          {...rest}
          alt={''}
          src={'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xc944E90C64B2c07662A292be6244BDf05Cda44a7/logo.png'}
          size={size}
          onError={(event) => {
            BAD_IMAGES[address] = true
            setError(true)
            event.preventDefault()
          }}
        />
      </Inline>
    )
  }

  else if (address?.toLowerCase() === '0x7dff46370e9ea5f0bad3c4e29711ad50062ea7a4') {
    return (
      <Inline>
        <Image
          {...rest}
          alt={''}
          src={'https://assets.coingecko.com/coins/images/4128/small/coinmarketcap-solana-200.png?1616489452'}
          size={size}
          onError={(event) => {
            BAD_IMAGES[address] = true
            setError(true)
            event.preventDefault()
          }}
        />
      </Inline>
    )
  }

  else if (address?.toLowerCase() === '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913' || address?.toLowerCase() === '0xaf88d065e77c8cc2239327c5edb3a432268e5831') {
    return (
      <Inline>
        <Image
          {...rest}
          alt={''}
          src={'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png'}
          size={size}
          onError={(event) => {
            BAD_IMAGES[address] = true
            setError(true)
            event.preventDefault()
          }}
        />
      </Inline>
    )
  }

  const path = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${networkActive}/assets/${isAddress(
    address
  )}/logo.png`

  return (
    <Inline>
      <Image
        {...rest}
        alt={''}
        src={path}
        size={size}
        onError={(event) => {
          BAD_IMAGES[address] = true
          setError(true)
          event.preventDefault()
        }}
      />
    </Inline>
  )
}
