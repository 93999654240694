import React, { useState } from 'react'
import { useActiveNetwork } from '../../contexts/LocalStorage'
import { ButtonDark } from '../ButtonStyled'
import { StyledIcon } from '..'
import { ChevronUp, ChevronDown } from 'react-feather'
import { NETWORKS } from '../../apollo/client'
import { RowBetween } from '../Row'
import styled from 'styled-components'

const DropdownContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;  // This ensures items go to the next line if they overflow
    align-items: center;
    max-width: 800px; // or any width that fits your design
`

const DropdownRow = styled.div`  // Changed from RowBetween to a div
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 2px;
    border: 1px solid grey;
    border-radius: 12px;
    padding: 0.25rem;
    transition: background-color 1s ease;

    &:hover {
        background-color: ${({ theme }) => theme.primary5};
    }
`

const NetworkLabel = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.text1}
    width: 80px;
`


function NetworkDropdown() {
    const [chainId, setChainId] = useActiveNetwork()
    const [isOpen, setIsOpen] = useState(false)

    const handleChange = (selectedChainId, event) => {
        event.stopPropagation()
        setChainId(selectedChainId)
        window.location.reload()
    }

    const selectedNetwork = NETWORKS.find(net => net.chainId === chainId)

    return (
        <ButtonDark open={isOpen} onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? (
                <DropdownContainer>
                    <StyledIcon>
                        <ChevronUp size={24} />
                    </StyledIcon>
                    {NETWORKS.map((network) => (
                        <DropdownRow
                            key={network.chainId}
                            onClick={(event) => handleChange(network.chainId, event)}
                            active={chainId === network.chainId}
                        >
                            <img src={network.logo} alt={network.label} height={24} width={24} style={{ marginRight: '8px' }} />
                            <NetworkLabel>{network.label}</NetworkLabel>
                        </DropdownRow>
                    ))}
                </DropdownContainer>
            ) : (
                <RowBetween>
                    <img src={selectedNetwork.logo} alt={selectedNetwork.label} height={24} width={24} style={{ marginRight: '8px' }} />
                    <NetworkLabel>{selectedNetwork.label}</NetworkLabel>
                    <StyledIcon><ChevronDown size={24} /></StyledIcon>
                </RowBetween>
            )}
        </ButtonDark>
    )
}

export default NetworkDropdown
