import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Link from '../Link'
import { Flex } from 'rebass'
import { RowFixed } from '../Row'
import LogoWhite from '../../assets/logo_text_white.png'

const TitleWrapper = styled.div`
  text-decoration: none;
  z-index: 10;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`

const FlashIcon = styled(Link)`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
`

/* const LogoText = styled.span`
  color: ${({ theme }) => theme.white};
  font-weight: bold;
  margin-left: 8px;
  font-size: 16px;
` */

export default function Title() {
  const history = useHistory()

  return (
    <TitleWrapper onClick={() => history.push('/')}>
      <Flex alignItems="center" style={{ justifyContent: 'space-between' }}>
        <RowFixed>
          <FlashIcon id="link" onClick={() => history.push('/')}>
            <img height={'36px'} src={LogoWhite} alt="logo" />
          </FlashIcon>
        </RowFixed>
      </Flex>
    </TitleWrapper>
  )
}
