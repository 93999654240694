import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import Polygon from '../assets/matic.png'
import Avalanche from '../assets/avalanche.png'
import BasedChain from '../assets/base.png'
import zkEVM from '../assets/polygon-hermez-logo.png'
import Arbitrum from '../assets/arbitrum.png'

export const NETWORKS = [
  { chainId: 137, label: 'Polygon', logo: Polygon, explorer: 'polygonscan.com', label2: 'polygon', label_long: 'Polygon PoS' },
  { chainId: 1101, label: 'zkEVM', logo: zkEVM, explorer: 'zkevm.polygonscan.com', label2: 'polygonzkevm', label_long: 'Polygon zkEVM' },
  { chainId: 8453, label: 'Base', logo: BasedChain, explorer: 'basescan.org', label2: 'optimism', label_long: 'BASE' },
  { chainId: 43114, label: 'Avalanche', logo: Avalanche, explorer: 'snowtrace.io', label2: 'avalanchec', label_long: 'Avalanche C-chain' },
  { chainId: 42161, label: 'Arbitrum', logo: Arbitrum, explorer: 'arbiscan.io', label2: 'arbitrum-one', label_long: 'Arbitrum One' }
]

export function getClient(chainId) {
  if (chainId === 137) return client
  else if (chainId === 1101) return clientZKEVM
  else if (chainId === 43114) return clientAVAX
  else if (chainId === 8453) return clientBASE
  else if (chainId === 42161) return clientARBITRUM
}

export function getHealthClient(chainId) {
  if (chainId === 137) return healthClient
  else if (chainId === 1101) return healthClientZKEVM
  else if (chainId === 43114) return healthClientAVAX
  else if (chainId === 8453) return healthClientBASE
  else if (chainId === 42161) return healthClientARBITRUM
}

export function getBlockClient(chainId) {
  if (chainId === 137) return blockClient
  else if (chainId === 1101) return blockClientZKEVM
  else if (chainId === 43114) return blockClientAVAX
  else if (chainId === 8453) return blockClientBASE
  else if (chainId === 42161) return blockClientARBITRUM
}

export const client = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.studio.thegraph.com/query/72402/flashliquidity/v0.0.1',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const clientZKEVM = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.studio.thegraph.com/query/72402/flashliquidity-zkevm/1.0.3',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const clientAVAX = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.studio.thegraph.com/query/72402/flashliquidity-avalanche/v0.0.1',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const clientBASE = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.studio.thegraph.com/query/72402/flashliquidity-base/0.0.1',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const clientARBITRUM = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.studio.thegraph.com/query/72402/flashliquidity-arbitrum-one/v0.0.1',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const healthClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/index-node/graphql',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const healthClientZKEVM = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/index-node/graphql',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const healthClientAVAX = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/index-node/graphql',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const healthClientBASE = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/index-node/graphql',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const healthClientARBITRUM = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/index-node/graphql',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const stakingClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/way2rach/talisman',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const blockClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/sameepsi/maticblocks',
  }),
  cache: new InMemoryCache(),
})

export const blockClientZKEVM = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.studio.thegraph.com/query/44554/zkevm-blocks/0.0.2',
  }),
  cache: new InMemoryCache(),
})

export const blockClientAVAX = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/dasconnor/avalanche-blocks',
  }),
  cache: new InMemoryCache(),
})

export const blockClientBASE = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/chimpydev/base-blocks',
  }),
  cache: new InMemoryCache(),
})

export const blockClientARBITRUM = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/ianlapham/arbitrum-one-blocks',
  }),
  cache: new InMemoryCache(),
})
// export const stakingClient = new ApolloClient({
//   link: new HttpLink({
//     uri: 'https://api.thegraph.com/subgraphs/name/way2rach/talisman',
//   }),
//   cache: new InMemoryCache(),
//   shouldBatch: true,
// })

// export const blockClient = new ApolloClient({
//   link: new HttpLink({
//     uri: 'https://api.studio.thegraph.com/query/44554/zkevm-blocks/0.0.2',
//   }),
//   cache: new InMemoryCache(),
// })
